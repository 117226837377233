var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "box", attrs: { id: "detail-page" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.config.loading,
            expression: "config.loading",
          },
        ],
        attrs: {
          id: "detail-content",
          "element-loading-text": "数据加载中,请耐心等待,谢谢!",
        },
      },
      [
        ["0", "2", "3", "4", 0, 2, 3, 4].includes(_vm.config.billStatus)
          ? _c("span", { staticClass: "nav-icon" }, [
              _c("img", {
                attrs: { width: "100%", src: _vm.detailIcon, alt: "加载中" },
              }),
            ])
          : _vm._e(),
        _c(
          "el-form",
          _vm._b(
            {
              ref: "myForm",
              attrs: {
                model: _vm.config,
                "label-width": _vm.config.labelWidth + "px",
              },
            },
            "el-form",
            _vm.$attrs,
            false
          ),
          [
            _vm.config.buttons && _vm.config.buttons.length
              ? _c("DetailButtons", {
                  attrs: {
                    id: "detail-buttons",
                    buttons: _vm.config.buttons,
                    tableConfig: _vm.config,
                  },
                  on: { handleEvent: _vm.handleEvent },
                })
              : _vm._e(),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-title" }, [
                _c("span", { staticClass: "title" }, [_vm._v("基本信息")]),
              ]),
              _c(
                "div",
                { staticClass: "card-content" },
                [
                  _c("DetailForm", {
                    attrs: {
                      id: "detail-form",
                      options: _vm.config.formItemOpt,
                    },
                    on: { handleEvent: _vm.handleEvent },
                    model: {
                      value: _vm.config,
                      callback: function ($$v) {
                        _vm.config = $$v
                      },
                      expression: "config",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-title" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.config.tableTitle || "列表信息")),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "card-content" },
                [
                  _c("DetailVxeTable", {
                    ref: "vxeTable",
                    on: { handleEvent: _vm.handleEvent },
                    model: {
                      value: _vm.config,
                      callback: function ($$v) {
                        _vm.config = $$v
                      },
                      expression: "config",
                    },
                  }),
                  _vm.config.fileItems && _vm.config.isFile
                    ? _c("div", { attrs: { id: "ossUpload" } })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }