var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          staticClass: "center-dialog",
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.showDialog,
            width: _vm.percentageWidth ? _vm.percentageWidth : _vm.width + "px",
            "before-close": _vm.handleBeforeClose,
            modal: _vm.modal,
            "append-to-body": "",
          },
        },
        [
          _c("div", [_vm._t("content")], 2),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [_vm._t("buttonList")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }