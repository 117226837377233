<template>
  <el-form v-bind="$attrs" ref="value" :model="value">
    <el-form-item
      :label="item.label"
      v-for="item in newOptions"
      :key="item.model"
      :rules="item.rules"
      :prop="item.model"
    >
      <el-input
        size="mini"
        v-model="value[item.model]"
        psasdsad="item.label"
        :disabled="item.disabled"
        :placeholder="item.tip || `请输入${item.label}`"
        :readonly="item.readonly || false"
        @keydown.native="
          (v) => (typeof item.keydown === 'function' ? typeof item.keydown(v) : undefined)
        "
        v-if="item.type === 'input'"
        :style="`width: ${item.style ? item.style.width : ''}px`"
        :maxlength="item.maxlength"
        @change="
          (v) => (typeof item.change === 'function' ? typeof item.change(v) : undefined)
        "
        :type="item.inputType || 'text'"
        @input="
          handleInput(
            $event,
            item.inputType || 'text',
            value,
            item.model,
            item.intMax,
            item.dotMax
          )
        "
      />
      <el-checkbox
        v-else-if="item.type === 'checkboxOne'"
        :true-label="true"
        :false-label='false'
        v-model="value[item.model]"
        >
        {{item.checkboxLabel || ''}}
      </el-checkbox>
      <el-checkbox
        v-else-if="item.type === 'checkbox-one'"
        v-for="(check, i) in item.option.data"
        :true-label="
          check[item.option.value || 'value'] === 0
            ? '0'
            : check[item.option.value || 'value']
        "
        false-label
        v-model="checkboxOneObj[item.model]"
        @change="(e) => checkboxOne(e, item)"
        :key="i"
        class="checkbox-one"
        :checked="
          value[item.model] === 0
            ? String(check[item.option.value || 'value']) === '0'
            : check[item.option.value || 'value'] === value[item.model]
        "
        >{{ check[item.option.label || "label"] }}
      </el-checkbox>
      <el-checkbox-group
        v-model="value[item.model]"
        v-else-if="item.type === 'checkbox-more'"
      >
        <el-checkbox
          v-for="(check, i) in item.option.data"
          :key="i"
          :label="check[item.option.value || 'value']"
          >{{ check[item.option.label || "label"] }}
        </el-checkbox>
      </el-checkbox-group>
      <el-radio-group
        size="mini"
        v-model="value[item.model]"
        :disabled="item.disabled"
        v-else-if="item.type === 'radio-button'"
      >
        <el-radio-button
          :label="optionItem.value"
          v-for="(optionItem, i) in item.optionData"
          :key="optionItem.value + i"
          >{{ optionItem.label }}
        </el-radio-button>
      </el-radio-group>
      <el-radio-group
        size="mini"
        v-model="value[item.model]"
        v-else-if="item.type === 'radio'"
        @change="(newVal) => {$emit('handleEvent','radio', newVal, item.model)}"
      >
        <el-radio
          :label="radio.value"
          v-for="(radio, i) in item.optionData"
          :key="`${radio.value}.${i}`"
          >{{ radio.label }}
        </el-radio>
      </el-radio-group>
      <div
        v-else-if="item.type === 'text-append'"
        style="height: 36px; line-height: 36px; padding: 0 15px; font-size: 14px"
      >
        <div style="font-size: 28px; color: red">{{ value[item.model] }}</div>
      </div>

      <!-- 时间选择器 -->
      <el-date-picker
        v-else-if="item.type === 'date-picker'"
        size="mini"
        v-model="value[item.model]"
        type="datetimerange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        value-format="yyyy-MM-dd HH:mm:ss" 
        :default-time="['00:00:00', '23:59:59']"
      >
      </el-date-picker>
      <el-input-number
        v-else-if="item.type === 'number'"
        :class="{ inputNumber: item.option.disabled }"
        :disabled="item.option.disabled"
        size="mini"
        class="number-view"
        :style="`width: ${item.option.style ? item.option.style.width : 140}px;`"
        v-model="value[item.model]"
        :min="item.option.min"
        :max="item.option.max"
        :controls="item.option.controls"
        :precision="item.option.precision || 0"
      />
      <div v-else-if="item.type === 'input-number'">
        <div class="flex-view">
          <el-input-number
            :class="{ inputNumber: item.disabled }"
            :disabled="item.disabled"
            size="mini"
            :style="`width: ${item.style ? item.style.width : 140}px`"
            v-model="value[item.model]"
            :min="item.min"
            :max="item.max"
            :precision="item.precision || 0"
          />
          <div v-if="item.append && item.append.type === 'xyt-users'" class="append-view">
            <span
              style="color: #f44417; font-size: 18px"
              v-if="item.append.getPrice() && item.append.getPrice().orderMoney"
            >
              {{ Number(item.append.getPrice().orderMoney).toFixed(2) }}元/{{
                item.append.getPrice().unit.replace("一年", "年")
              }}
            </span>
            <span
              v-if="item.append.getPrice() && item.append.getPrice().oldOrderMoney"
              style="color: #999999; font-size: 12px"
            >
              原价{{ item.append.getPrice().oldOrderMoney.toFixed(2) }}元/{{
                item.append.getPrice().unit.replace("一年", "年")
              }}
            </span>

            <!-- 折扣价 -->
            <span
              v-if="item.append.getPrice() && +item.append.getPrice().discounted >= 0"
              style="color: #999999; font-size: 12px"
            >
              折扣价{{ item.append.getPrice().discounted }}元
            </span>
          </div>
        </div>
        <div v-if="item.append && item.append.type === 'xyt-users' && item.append.show">
          <span
            style="color: #999999; font-size: 16px"
            v-if="item.model === 'userNumber'"
          >
            <span v-if="item.initializeUsers > 0 && item.append.isInitial"
              >含{{ item.initializeUsers }}个初始用户</span
            >
            <span
              v-if="
                item.initializeUsersPrice > 0 &&
                item.initializeUsers > 0 &&
                item.append.isInitial
              "
              >{{ (item.initializeUsers * item.initializeUsersPrice).toFixed(2) }}元/{{
                item.append.getPrice().unit.replace("一年", "年")
              }}</span
            >
            <span
              v-if="
                item.initializeUsersPrice > 0 &&
                item.initializeUsers > 0 &&
                item.append.getPrice().showUsersPrice &&
                item.append.isInitial
              "
              >，</span
            >
            <span v-if="item.append.getPrice().showUsersPrice"
              >每增加1用户收费￥{{ item.append.getPrice().showUsersPrice }}元/{{
                item.append.getPrice().unit.replace("一年", "年")
              }}</span
            >
          </span>
          <span
            style="color: #999999; font-size: 16px"
            v-if="item.model === 'shopNumber'"
          >
            <span v-if="item.initializeShops > 0 && item.append.isInitial"
              >含{{ item.initializeShops }}个初始门店</span
            >
            <span
              v-if="
                item.initializeShopsPrice > 0 &&
                item.initializeShops > 0 &&
                item.append.isInitial
              "
              >{{ (item.initializeShops * item.initializeShopsPrice).toFixed(2) }}元/{{
                item.append.getPrice().unit.replace("一年", "年")
              }}</span
            >
            <span
              v-if="
                item.initializeShopsPrice > 0 &&
                item.initializeShops > 0 &&
                item.append.getPrice().showShopPrice &&
                item.append.isInitial
              "
              >，</span
            >
            <span v-if="item.append.getPrice().showShopPrice"
              >每增加1门店收费￥{{ item.append.getPrice().showShopPrice }}元/{{
                item.append.getPrice().unit.replace("一年", "年")
              }}</span
            ></span
          >
          <span
            style="color: #999999; font-size: 16px"
            v-if="item.model === 'possNumber'"
          >
            <span v-if="item.initializePoss > 0 && item.append.isInitial"
              >含{{ item.initializePoss }}个初始站点</span
            >
            <span
              v-if="
                item.initializePossPrice > 0 &&
                item.initializePoss > 0 &&
                item.append.isInitial
              "
              >{{ (item.initializePoss * item.initializePossPrice).toFixed(2) }}元/{{
                item.append.getPrice().unit.replace("一年", "年")
              }}</span
            >
            <span
              v-if="
                item.initializePossPrice > 0 &&
                item.initializePoss > 0 &&
                item.append.getPrice().showPossPrice &&
                item.append.isInitial
              "
              >，</span
            >
            <span v-if="item.append.getPrice().showPossPrice"
              >每增加1站点收费￥{{ item.append.getPrice().showPossPrice }}元/{{
                item.append.getPrice().unit.replace("一年", "年")
              }}</span
            ></span
          >
        </div>
      </div>
      <div v-else-if="item.type === 'table'">
        <el-table
          :ref="item.ref"
          border
          :data="item.option.tableData"
          style="width: 746px"
          @selection-change="item.option.handleSelectionChange"
        >
          <el-table-column
            align="center"
            type="selection"
            v-if="!item.eltableCheck"
            width="55"
          ></el-table-column>
          <el-table-column
            align="center"
            label="序号"
            type="index"
            width="55"
          ></el-table-column>
          <el-table-column
            align="center"
            v-for="(tab, tabIndex) in item.option.tableHand"
            :key="tabIndex"
            :prop="tab.prop"
            :label="tab.label"
            :width="tab.width"
          ></el-table-column>
        </el-table>
      </div>
      <!-- 数字 输入框 -->
      <el-input
        size="mini"
        v-else-if="item.type === 'tel'"
        v-model="value[item.model]"
        :disabled="item.disabled"
        type="number"
        @input="(v) => changeNumber(v, item.model)"
        :placeholder="item.tip || `请输入${item.label}`"
        :style="`width: ${item.style ? item.style.width : ''}px`"
      />
      <el-divider v-else-if="item.type === 'divider'"></el-divider>
      <!-- 上传 -->
      <OssUpload
        v-model="value[item.model]"
        v-else-if="item.type === 'upload-one'"
        :option="item.option"
        :style="`width: ${item.style ? item.style.width : ''}px`"
      />
      <el-input
        size="mini"
        v-else-if="item.type === 'textarea'"
        v-model="value[item.model]"
        :disabled="item.disabled"
        type="textarea"
        :rows="2"
        :autosize="{ minRows: 2, maxRows: 100 }"
        placeholder="请输入内容"
        :maxlength="item.maxlength"
      />
      <!-- 远程数据选择器 -->
      <SelectRemote
        size="mini"
        v-else-if="item.type === 'remote'"
        v-model="value[item.model]"
        :option="item.option"
        :filterable="item.option.filterable || false"
        :multiple="item.option.multiple || false"
        :clearable="item.option.clearable || false"
        :collapse-tags="item.option['collapse-tags'] || false"
        :placeholder="item.placeholder || `选择${item.label}`"
      />
      <SelectTree
        v-else-if="item.type === 'tree'"
        v-model="value[item.model]"
        :option="item.option"
        :filterable="item.option.filterable || false"
        :multiple="item.option.multiple || false"
        :clearable="item.option.clearable || false"
        :collapse-tags="item.option['collapse-tags'] || false"
        :placeholder="item.placeholder || `选择${item.label}`"
      />
      <SelectLocal
        :style="{ width: item.width }"
        v-else-if="item.type === 'local'"
        v-model="value[item.model]"
        :option="item.option"
        :filterable="item.option.filterable || false"
        :multiple="item.option.multiple || false"
        :clearable="item.option.clearable || false"
        :collapse-tags="item.option['collapse-tags'] || false"
        :placeholder="item.placeholder || `选择${item.label}`"
      />
      <el-switch
        size="mini"
        v-else-if="item.type === 'switch'"
        v-model="value[item.model]"
        :active-value="item.open"
        :inactive-value="item.close"
      />
      <el-cascader
        size="mini"
        v-else-if="item.type === 'cascader'"
        :style="`width: ${item.style ? item.style.width + 'px' : '100%'}`"
        v-model="value[item.model]"
        :placeholder="item.placeholder || `选择${item.label}`"
        :options="item.option ? item.option.data : []"
        :clearable="item.option.clearable || false"
        :filterable="item.option.filterable || false"
        :value="item.option.value"
        :label="item.option.label"
        :props="{
          value: item.option.value,
          label: item.option.label,
          emitPath: item.option.emitPath,
        }"
      />
      <div v-else-if="item.type === 'editor'">
        <el-upload
          v-show="false"
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="uploadSuccess"
          :before-upload="beforeUpload"
        />
        <quill-editor
          v-model="value[item.model]"
          :options="editorOption"
          ref="QuillEditor"
        />
      </div>
      <div v-else-if="item.type === 'slot'">
       <slot name="slot"></slot>
      </div>
    </el-form-item>
  </el-form>
</template>
<script>
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import OssUpload from "@/components/oss-upload/index.vue";
// import SearchSelect from "@/components/tablePage/select/select-search/index.vue";
import SelectTree from "@/components/tablePage/select/select-tree/index.vue";

import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { validateTelephone } from "@/api/codeRule";
import { inputLimit } from "@/utils/numFormatting";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
  ["blockquote", "code-block"], //引用，代码块
  [{ header: 1 }, { header: 2 }], // 几级标题
  [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表
  [{ script: "sub" }, { script: "super" }], // 下角标，上角标
  [{ indent: "-1" }, { indent: "+1" }], // 缩进
  [{ direction: "rtl" }], // 文字输入方向
  [{ size: ["small", false, "large", "huge"] }], // 字体大小
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ color: [] }, { background: [] }], // 颜色选择
  [
    {
      font: ["SimSun", "SimHei", "Microsoft-YaHei", "KaiTi", "FangSong", "Arial"],
    },
  ], // 字体
  [{ align: [] }], // 居中
  ["clean"], // 清除样式,
  ["link", "image"], // 上传图片、上传视频
];
export default {
  name: "myForm",
  components: { SelectRemote, SelectLocal, SelectTree, quillEditor, OssUpload },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: "value", // 指定 v-model 要绑定的参数叫什么名字，来自于 props 中定义的参数
    event: "change", // 指定要触发的事件名字，将被用于 $emit
  },
  computed: {
    newOptions: {
      get() {
        return this.options;
      },
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      checkboxOneObj: {},
      editorOption: {
        placeholder: "请在这里输入",
        theme: "snow", //主题 snow/bubble
        modules: {
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false,
          },
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: function (value) {
                if (value) {
                  // 调用element的图片上传组件
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/open/oss/file/upload", // 服务器上传地址
    };
  },
  methods: {
    inputLimit,
    handleINput(e, inputType, value, model, intMax, dotMax) {
      if (inputType !== "text") {
        inputLimit(e, intMax || 8, dotMax || 4, value, model);
      }
    },
    checkboxOne(e, item) {
      const isNum = typeof item.option.data[0][item.option.value || "value"] === "number";
      this.value[item.model] = e === "0" && isNum ? 0 : e;
    },
    // 验证手机号
    changeNumber(v, model) {
      this.value[model] = String(v)?.length > 11 ? Number(String(v).slice(0, 11)) : v;
      // console.log('手机号：', v, this.value[model])
      // if(this.value[model].length == 11){
      //     validateTelephone({
      //         telephone: this.value[model]
      //     }).then(res => {
      //         if(!res.enable){
      //             this.value[model] = ''
      //             this.$message.warning('此手机号已被注册，不可使用')
      //         }
      //     })
      // }
    },
    //删除行事件
    tableDelRow(row) {
      this.$emit("delTableRow", row);
    },
    // 值发生变化
    onEditorChange(editor) {
      this.content = editor.html;
    },
    beforeUpload(file) {},
    uploadSuccess(res) {
      // 获取富文本组件实例
      try {
        let quill = this.$refs.QuillEditor[0].quill;
        // 如果上传成功
        if (res) {
          // 获取光标所在位置
          let length = quill.getSelection().index;
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, "image", res.data.accessUrl);
          // 调整光标到最后
          quill.setSelection(length + 1);
          return true;
        }
      } catch (error) {}
      this.$message.error("图片插入失败！");
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-view {
  display: flex;
  align-items: center;

  .append-view {
    margin-left: 5px;
  }
}

.none-select {
  -webkit-user-select: none; /* webkit浏览器 */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none; /* 标准语法 */
}

::v-deep .ql-container {
  height: 180px;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkbox-one {
  ::v-deep .el-checkbox__inner {
    border-radius: 100% !important;
  }
}

.inputNumber {
  ::v-deep .el-input__inner {
    background-color: #ffffff;
    color: #333333;
  }
}
.number-view {
  ::v-deep .el-input__inner {
    text-align: left;
  }
}

//在全局设置
::v-deep input[aria-hidden=true]{
    display: none !important;
}
</style>
