var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editTable" },
    [
      _c(
        "vxe-table",
        {
          key: _vm.config.tableKey,
          ref: "xTable",
          attrs: {
            border: "",
            "show-overflow": "",
            id: "xTable",
            data: _vm.config.list,
            height: _vm.config.tableHeight,
            "row-config": { isHover: true, isCurrent: true },
            "scroll-y": { enabled: true },
            "footer-method": _vm.footerMethod,
            "show-footer": Boolean(this.config.summary),
            "edit-rules": _vm.config.rules,
            "edit-config": !["2", "3", "4"].includes(_vm.config.billStatus)
              ? { trigger: "click", mode: "row", autoClear: false }
              : undefined,
            "mouse-config": { selected: true },
            "keep-source": "",
            "keyboard-config": {
              isArrow: true,
              isEsc: true,
              isDel: true,
              isTab: true,
              isEdit: true,
            },
            "checkbox-config": { checkMethod: _vm.config.checkMethod },
          },
          on: {
            "checkbox-all": _vm.checkboxAll,
            "checkbox-change": _vm.checkboxChange,
            "cell-mouseenter": _vm.mouseenter,
          },
        },
        [
          _vm.config.mutiSelect
            ? _c("vxe-column", {
                attrs: {
                  type: "checkbox",
                  align: "center",
                  width: "55",
                  fixed: "left",
                  disabled: _vm.config.disabled,
                },
              })
            : _vm._e(),
          _c("vxe-column", {
            attrs: {
              align: "center",
              width: "50",
              title: "序号",
              fixed: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var rowIndex = ref.rowIndex
                  return [
                    _vm.config.pagination &&
                    _vm.config.pagination.page &&
                    _vm.config.pagination.size
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              rowIndex +
                                1 +
                                (_vm.config.pagination.page - 1) *
                                  _vm.config.pagination.size
                            )
                          ),
                        ])
                      : _c("span", [_vm._v(_vm._s(rowIndex + 1))]),
                  ]
                },
              },
            ]),
          }),
          !_vm.config.notOperaColumn &&
          !["2", "3", "4"].includes(_vm.config.billStatus)
            ? _c("vxe-column", {
                attrs: {
                  align: "center",
                  width: "70",
                  title: "操作",
                  field: "com_opera_column",
                  fixed: "left",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var rowIndex = ref.rowIndex
                        return [
                          _c("span", { staticClass: "operaBtn" }, [
                            !_vm.config.notPushBtn
                              ? _c("i", {
                                  staticClass:
                                    "el-icon-circle-plus operatePush",
                                  class: { disabled: _vm.config.disabled },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      if (
                                        $event.target !== $event.currentTarget
                                      ) {
                                        return null
                                      }
                                      return _vm.handleTableRow(
                                        "push",
                                        rowIndex
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            !_vm.config.notDelBtn
                              ? _c("i", {
                                  staticClass: "el-icon-remove operateDel",
                                  class: { disabled: _vm.config.disabled },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      if (
                                        $event.target !== $event.currentTarget
                                      ) {
                                        return null
                                      }
                                      return _vm.handleTableRow("del", rowIndex)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  892398768
                ),
              })
            : _vm._e(),
          _vm._l(_vm.config.columns, function (item, i) {
            return _c("TableColumns", {
              key: item.prop + "." + i,
              ref: "TableColumns",
              refInFor: true,
              attrs: {
                column: Object.assign({}, item, { columnIndex: i }),
                columns: _vm.config.columns,
              },
              on: {
                handleEvent: _vm.handleEvent,
                handleKeyboardEvents: _vm.handleKeyboardEvents,
              },
            })
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.config.hideTotal,
              expression: "!config.hideTotal",
            },
          ],
          staticClass: "left-view",
        },
        [
          [
            _c("span", [_vm._v("共")]),
            _c("span", { staticClass: "num", staticStyle: { color: "#333" } }, [
              _vm._v(_vm._s((_vm.config.list && _vm.config.list.length) || 0)),
            ]),
            _c("span", [_vm._v("条")]),
            _vm.config.mutiSelect
              ? [
                  _c("span", [_vm._v("，已选")]),
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(_vm.config.check.length)),
                  ]),
                  _c("span", [_vm._v("条")]),
                ]
              : _vm._e(),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }