<template>
  <component
    :is="column.children ? 'vxe-colgroup' : 'vxe-column'"
    :title="column.label"
    :width="column.width"
    :min-width="column.minWidth"
    :field="column.prop"
    :fixed="column.fixed || undefined"
    :align="column.align || 'center'"
    :show-overflow-tooltip="!['html', 'slot', 'slots', 'tag'].includes(column.type)"
    :edit-render="
      column.type ? { autofocus: '.vxe-input--inner', autoselect: true } : undefined
    "
  >
    <!-- :edit-render="
      column.type ? { autofocus: '.vxe-input--inner', autoselect: true } : undefined
    " -->
    <!-- <template slot="header">
      <i style="color: #ff4949" v-if="column.rules">* </i>
      <span>{{ column.label }}</span>
    </template> -->
    <template v-if="column.children">
      <vxe-column
        v-for="(item, i) in typeof column.children === 'function'
          ? column.children()
          : column.children"
        :key="`${item.prop}.${i}`"
        :title="item.label"
        :field="item.prop"
        :width="item.width"
        :min-width="item.minWidth"
        :align="item.align || 'center'"
        :edit-render="
          item.type ? { autofocus: '.vxe-input--inner', autoselect: true } : undefined
        "
      >
        <template #default="{ row }">
          <span>{{ item.showProp ? row[item.showProp] : row[item.prop] }}</span>
        </template>
        <template #edit="{ row, rowIndex }">
          <!-- 输入框 -->
          <vxe-input
            v-if="item.type == 'input'"
            v-model="row[item.prop]"
            @input="onInput(item.input, row, rowIndex, item.prop, item.notVerifyNum)"
            :disabled="column.disabled"
            size="small"
            :ref="item.prop + '_' + rowIndex + '_' + item.columnIndex"
            @keydown.native="
              handleKeyboardEvents({
                key: $event.key,
                prop: item.prop,
                rowIndex: rowIndex,
                columnIndex: item.columnIndex,
              })
            "
          />
          <!-- 本地数据选择器 -->
          <SelectLocal
            v-else-if="item.type === 'localSelect'"
            v-model="row[item.prop]"
            :row.sync="row"
            :option="item.option"
            :rowIndex="rowIndex"
            :columnIndex="item.columnIndex"
            :filterable="item.option.filterable || false"
            :multiple="item.option.multiple || false"
            :disabled="item.disabled"
            :clearable="item.option.clearable || false"
            :collapse-tags="item.option['collapse-tags']"
            :placeholder="item.placeholder || `选择${item.label}`"
            @selectChange="selectChange($event, item.change, rowIndex, item.prop)"
          />
          <!-- 远程数据选择器 -->
          <SelectRemote
            v-else-if="item.type === 'remoteSelect'"
            v-model="row[item.prop]"
            :option="
              Object.assign(item.option, {
                curTableIndex: rowIndex,
                disabled: item.disabled,
              })
            "
            :filterable="item.option.filterable || false"
            :multiple="item.option.multiple || false"
            :clearable="item.option.clearable || false"
            :collapse-tags="item.option['collapse-tags']"
            :placeholder="item.placeholder || `选择${item.label}`"
            @handleEvent="
              (type, option) => {
                $emit('handleEvent', type, option);
              }
            "
            :disabled="item.disabled"
          />
          <!-- 默认 -->
          <template v-else>{{
            item.formatter
              ? item.formatter(row[item.prop], row, columnObj)
              : row[item.prop]
          }}</template>
        </template>
      </vxe-column>
    </template>
    <slot v-if="column.type === 'slot'" />
    <template #default="{ row }" v-if="!column.children">
      <span v-if="column.formatter">{{ column.formatter(row[column.prop], row) }}</span>
      <span v-else>{{ column.showProp ? row[column.showProp] : row[column.prop] }}</span>
    </template>
    <template #edit="{ row, rowIndex }" v-if="!column.children">
      <!-- 输入框 -->
      <vxe-input
        v-if="column.type == 'input'"
        v-model="row[column.prop]"
        @input="onInput(column.input, row, rowIndex, column.prop, column.notVerifyNum)"
        :disabled="column.disabled"
        size="small"
        :ref="column.prop + '_' + rowIndex + '_' + column.columnIndex"
        @keydown.native="
          handleKeyboardEvents({
            key: $event.key,
            prop: column.prop,
            rowIndex: rowIndex,
            columnIndex: column.columnIndex,
          })
        "
      />
      <!-- 链接内容 -->
      <el-link
        :disabled="
          typeof column.disabled === 'function' ? column.disabled(row) : column.disabled
        "
        v-if="column.link || column.type === 'link'"
        type="primary"
        class="sys-link-a"
        @click.stop="onClick(column.click, row)"
        >{{
          column.formatter
            ? column.formatter(row[column.prop], row, columnObj)
            : row[column.prop]
        }}</el-link
      >
      <template v-else-if="column.type === 'switch'">
        <el-switch
          :disabled="
            typeof column.disabled === 'function' ? column.disabled(row) : column.disabled
          "
          v-model="row[column.prop]"
          :active-value="column.active"
          :inactive-value="column.inactive"
          @change="onClick(column.click, row)"
        />
      </template>
      <!-- 时间段 -->
      <el-time-picker
        class="time-picker"
        v-else-if="column.type === 'timePicker'"
        v-model="row[column.prop]"
        format="HH:mm"
        value-format="yyyy-MM-dd HH:mm:ss"
        placeholder="任意时间点"
        size="small"
        :disabled="column.disabled"
      >
      </el-time-picker>
      <!-- 本地数据选择器 -->
      <SelectLocal
        v-else-if="column.type === 'localSelect'"
        v-model="row[column.prop]"
        :row.sync="row"
        :option="column.option"
        :rowIndex="rowIndex"
        :columnIndex="column.columnIndex"
        :filterable="column.option.filterable || false"
        :multiple="column.option.multiple || false"
        :disabled="column.disabled"
        :clearable="column.option.clearable || false"
        :collapse-tags="column.option['collapse-tags']"
        :placeholder="column.placeholder || `选择${column.label}`"
        @selectChange="selectChange($event, column.change, rowIndex, column.prop)"
        :ref="column.prop + '_' + rowIndex + '_' + column.columnIndex"
        @keydown="
          handleKeyboardEvents({
            key: $event,
            prop: column.prop,
            rowIndex: rowIndex,
            columnIndex: column.columnIndex,
          })
        "
      />
      <!-- 远程数据选择器 -->
      <!-- 
                @keydown="
          handleKeyboardEvents({
            key: $event,
            prop: column.prop,
            rowIndex: rowIndex,
            columnIndex: column.columnIndex,
          })
        "
       -->
      <SelectRemote
        v-else-if="column.type === 'remoteSelect'"
        v-model="row[column.prop]"
        :option="
          Object.assign(column.option, {
            curTableIndex: rowIndex,
            disabled: column.disabled,
          })
        "
        :rowIndex="rowIndex"
        :columnIndex="column.columnIndex"
        :filterable="column.option.filterable || false"
        :multiple="column.option.multiple || false"
        :clearable="column.option.clearable || false"
        :collapse-tags="column.option['collapse-tags']"
        :placeholder="column.placeholder || `选择${column.label}`"
        @handleEvent="
          (type, option) => {
            $emit('handleEvent', type, option);
          }
        "
        :disabled="column.disabled"
        :ref="column.prop + '_' + rowIndex + '_' + column.columnIndex"
        @keydown="
          handleKeyboardEvents({
            key: $event,
            prop: column.prop,
            rowIndex: rowIndex,
            columnIndex: column.columnIndex,
          })
        "
      />
      <!-- 默认 -->
      <template v-else>{{
        column.formatter
          ? column.formatter(row[column.prop], row, columnObj)
          : row[column.prop]
      }}</template>
    </template>
    <el-dialog :visible.sync="dialogImageVisible" :append-to-body="true">
      <!-- 不为数组时则展示一张 -->
      <img class="image" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </component>
</template>
<script>
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import { limitInputlength } from "@/utils";

// 默认图片
// import defaultPic from "@/assets/fixedImages/logo/sunyun-logo.png";

export default {
  name: "DetailPageTableColumns",
  components: { SelectRemote, SelectLocal },
  props: {
    column: Object, // 详情见 element table option
  },
  data() {
    return {
      // defaultPic: defaultPic, //默认商户logo
      dialogImageVisible: false,
      dialogImageUrl: "",
    };
  },
  computed: {
    dicts() {
      let dicts = null;
      for (let index = 0; index < 10; index++) {
        dicts = dicts?.$parent || this;
        if (dicts?.$children?.find?.((x) => x.isTablePageCom) && dicts?.dict?.type) {
          dicts = dicts?.dict?.type;
          break;
        } else if (index === 9) {
          dicts = null;
        }
      }
      return dicts || {};
    },
  },
  methods: {
    //保留小数位
    limitInputlength,
    /** 图片放大处理 */
    showBigImage(url) {
      this.dialogImageUrl = url;
      this.dialogImageVisible = true;
    },

    // 层级往下，寻找字典数组
    getDictsList(name) {
      return (
        this.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.$parent?.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.$parent?.$parent?.$parent?.$parent?.dict?.type[
          name
        ] ||
        {}
      );
    },

    // 查询字典值
    getDictsLabel({ name, prop }, row) {
      return this.selectDictLabel(this.getDictsList(name), row[prop]);
    },

    getDictLabel({ dict, prop, name } = {}, row) {
      const item =
        this.dicts?.[dict]?.find?.((x) => String(x.value) === String(row[prop])) || {};
      return item;
    },
    //更改事件
    selectChange({ val, obj }, change, index, prop) {
      obj.index = index;
      obj.prop = prop;
      this.$emit("handleEvent", change, obj);
    },
    // 点击事件
    onClick(click, row = null, index, prop) {
      if (row.disabled) return;
      row.index = index;
      row.prop = prop;
      this.$emit("handleEvent", click, row);
    },
    //输入事件
    onInput(input, row = null, index, prop, notVerifyNum) {
      if (!notVerifyNum)
        this.$set(row, prop, this.limitInputlength(row[prop], row, prop, true, 8, true)); //限制小数位
      row.index = index;
      row.prop = prop;
      this.$emit("handleEvent", input, row);
      this.$emit("handleEvent", "updateData");
    },
    handleKeyboardEvents(e) {
      console.log("进来了吗键盘1", e);
      setTimeout(() => {
        this.$emit("handleKeyboardEvents", e);
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.sys-link-a {
  &:hover {
    text-decoration: underline;
    &:after {
      display: none;
    }
  }
}

p {
  margin: 0 !important;
}

.set-popper {
  width: 331px;
  max-height: 240px;
  overflow: hidden;
  overflow-y: auto;
}
.set-content {
  cursor: default;
  height: 60px;
  overflow-y: auto;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.el-form-item {
  margin-bottom: 0 !important;
}

::v-deep .el-form-item__content {
  margin-left: 0 !important;
}
::v-deep .el-form-item__error {
  position: static;
}
.time-picker {
  ::v-deep .el-input__inner {
    width: 100px !important;
  }
}

.image {
  width: 30vh;
}
</style>
