<template>
  <div id="detail-page" ref="box">
    <div
      id="detail-content"
      v-loading="config.loading"
      element-loading-text="数据加载中,请耐心等待,谢谢!"
    >
      <span
        class="nav-icon"
        v-if="['0', '2', '3', '4', 0, 2, 3, 4].includes(config.billStatus)"
      >
        <img width="100%" :src="detailIcon" alt="加载中" />
      </span>
      <el-form
        v-bind="$attrs"
        ref="myForm"
        :model="config"
        :label-width="`${config.labelWidth}px`"
      >
        <!-- 操作按钮组 -->
        <DetailButtons
          id="detail-buttons"
          v-if="config.buttons && config.buttons.length"
          :buttons="config.buttons"
          :tableConfig="config"
          @handleEvent="handleEvent"
        />
        <!-- 基本信息 -->
        <div class="card">
          <div class="card-title">
            <span class="title">基本信息</span>
          </div>
          <div class="card-content">
            <DetailForm
              id="detail-form"
              v-model="config"
              :options="config.formItemOpt"
              @handleEvent="handleEvent"
            />
          </div>
        </div>
        <div class="card">
          <div class="card-title">
            <span class="title">{{ config.tableTitle || "列表信息" }}</span>
          </div>
          <div class="card-content">
            <!-- 表格 -->
            <DetailVxeTable v-model="config" @handleEvent="handleEvent" ref="vxeTable" />
            <!-- 附件上传 -->
            <div id="ossUpload" v-if="config.fileItems && config.isFile">
              <!-- <AccessoryUpload
            v-model="config.fileItems"
            :fileList="
              config.fileItems.map((item) => ({
                ...item,
                name: item.fileName,
                url: item.accessUrl,
              }))
            "
            :disabled="['2', '3', '4'].includes(config.billStatus)"
          /> -->
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <!-- 弹窗 -->
    <!-- <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" /> -->
    <!-- 打印 -->
    <!-- <ShopBusinessPrint ref="print" :printData="[config]" /> -->
  </div>
</template>

<script>
import DetailForm from "@/components/billDetailPage/detailForm";
import DetailButtons from "@/components/billDetailPage/detailButtons";
import DetailVxeTable from "@/components/billDetailPage/detailVxeTable";

// import Dialog from "@/components/dialog"; //公共弹窗

// import ShopBusinessPrint from "@/components/print/ShopBusinessPrint.vue";

import OssUpload from "@/components/oss-upload";
// import AccessoryUpload from "@/components/accessoryUpload";

import dialogConfig from "@/utils/dialog-config.js";

// import { searchUserData } from "@/indexDB/userData"; //用户信息(本地数据)
// import { searchSyncData } from "@/indexDB/synchronizeData"; //基础数据(本地数据)
import { deepCopy, JSONParse } from "@/utils";
// import { shopGoodsList } from "@/api/public.js"; //类别
import { mixin } from "@/components/billDetailPage/mixin.js";

export default {
  name: "DetailPage",
  components: {
    DetailButtons,
    DetailForm,
    DetailVxeTable,
    OssUpload,
    cardTitleCom: () => import("@/views/components/cardTitleCom"),
    // Dialog,
    // AccessoryUpload,
    // ShopBusinessPrint,
  },
  model: { prop: "options", event: "Device" },
  props: {
    options: { type: Object, required: true },
  },
  mixins: [mixin],
  data() {
    return {
      detailPageH: 0,
      detailFormH: 0,
      detailOssUploadH: 0,
      //弹窗配置
      dialogOptions: {
        show: false,
        width: 1100,
        type: "TreeAndTable",
        title: "选择商品",
        click: "getGoods",
        diaData: dialogConfig({ key: "goods" }),
      },
      // detailIcon: require("@/assets/fixedImages/shopBusiness/noAudit.png"),
      detailIcon: require("@/assets/images/noAudit.png"),
    };
  },
  computed: {
    config: {
      get() {
        return this.options;
      },
      set(val) {
        this.$emit("Device", val);
      },
    },
  },
  watch: {
    "config.billStatus": {
      handler(val) {
        switch (val) {
          case "0":
          case 0:
            this.detailIcon = require("@/assets/images/noAudit.png");
            break;
          case "2":
          case 2:
            this.detailIcon = require("@/assets/images/audit.png");
            break;
          case "3":
          case 3:
            this.detailIcon = require("@/assets/images/delete.png");
            break;
          case "4":
          case 4:
            this.detailIcon = require("@/assets/images/stop.png");
            break;
          default:
            break;
        }
      },
      immediate: true,
    },
  },
  async created() {
    // try {
    //   this.config.tableKey = Math.random();
    //   // 自定义列配置
    //   let columns = await searchSyncData(
    //     "key",
    //     "shop_business_table_setting",
    //     "",
    //     this.$route.name
    //   );
    //   this.config.columns = columns
    //     ? JSON.parse(columns.list)
    //         .filter(
    //           (item) =>
    //             item.show &&
    //             (["MoveInDetail", "MoveOutDetail"].includes(this.$route.name)
    //               ? item.label != "进价金额"
    //               : true)
    //         )
    //         .map((item) => ({
    //           ...item,
    //           minWidth: item.customWidth,
    //           label: item.customLabel,
    //           type: this.config.columns.find((x) => x.label === item.label)?.type,
    //           option: item.option
    //             ? this.config.columns.find((x) => x.label === item.label)?.option
    //             : {},
    //         }))
    //     : this.config.columns;
    //   await this.$refs.vxeTable.$refs.xTable.refreshColumn();

    //   console.log("table配置", this.config.columns);
    // } catch (err) {}
    this.handleResize();
  },
  async activated() {
    this.$refs?.fastGoods?.focus();
    this.handleResize(); //自适应高度
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    //自适应获取高度
    async handleResize() {
      setTimeout(() => {
        this.detailPageH = document.getElementById("detail-page")?.offsetHeight || 0;
        this.detailFormH = document.getElementById("detail-form")?.offsetHeight || 0;
        this.detailOssUploadH = document.getElementById("ossUpload")?.offsetHeight || 0;
        let tableHeight =
          this.detailPageH -
          this.detailFormH -
          this.detailOssUploadH -
          (this.config.buttons ? 40 : 0) -
          40 -
          48 -
          55;
        this.$set(this.config, "tableHeight", tableHeight);
      }, 10);
    },
    async handleEvent(type, row, e) {
      switch (type) {
        case "print":
          this.$refs.print.shopPrint();
          break;
        case "columnsSet":
          this.dialogOptions = {
            width: 900,
            show: true,
            title: "列表配置",
            type: "ColumnsSet",
            diaData: {
              columns: this.config.columns,
            },
          };
          break;
        case "updateColumns":
          this.config.tableKey = Math.random();
          this.config.columns = deepCopy(e)
            .filter((item) => item.show)
            .map((item) => ({
              ...item,
              minWidth: item.customWidth,
              label: item.customLabel,
              type: this.config.columns.find((x) => x.label === item.label)?.type,
              option: item.option
                ? this.config.columns.find((x) => x.label === item.label)?.option
                : {},
            }));
          await this.$refs.vxeTable.$refs.xTable.refreshColumn();
          break;
        case "addGoods": // 根据条码编码搜索商品 并添加
          // if (!this.config.searchVal) return;
          // try {
          //   let shopId = await searchUserData("shopId");
          //   const { rows = [] } = await shopGoodsList({
          //     accurateQuery: this.config.searchVal,
          //     shopId,
          //   });
          //   if (rows?.length === 1) {
          //     this.$emit("handleEvent", "getGoods", { diaData: rows[0] });
          //   } else {
          //     this.$message.warning("查询不到数据");
          //   }
          // } catch (err) {
          //   this.$message.warning(`搜索失败${err.message || err}`);
          // } finally {
          //   this.config.searchVal = "";
          //   this.$refs?.fastGoods?.focus();
          // }
          break;
        default:
          this.$emit("handleEvent", type, row);
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#detail-page {
  height: calc(100vh - 84px);
  padding-right: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #eaeaea;
  #detail-returnBtn {
    height: 5%;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 4px;
    .icon {
      width: 80px;
      text-align: left;
      padding-left: 10px;
      color: #333;
      font-weight: bold;
      display: flex;
      align-items: center;
    }
    .title {
      width: calc(100% - 80px);
    }
  }
  #detail-content {
    height: 94.5%;
    border-radius: 4px;
    // background-color: #fff;
    padding: 5px 10px;
    position: relative;
    .card {
      background-color: #fff;
      margin-bottom: 6px;
      .card-title {
        padding-left: 10px;
        height: 40px;
        border-bottom: 1px solid #dedede;
        display: flex;
        align-items: center;
        width: 100%;
        .title {
          color: #333333;
          font-weight: bold;
          font-size: 14px;
          padding-left: 6px;
          border-left: 3px solid #1596fd;
        }
      }
      .card-content {
        padding: 6px;
      }
    }
    //图标
    .nav-icon {
      position: absolute;
      right: 10px;
      top: 35px;
      width: 100px;
      height: 48px;
      z-index: 777;
    }
    .search-goods {
      display: flex;
      margin-bottom: 5px;
      .el-input {
        width: 260px;
        margin-right: 10px;
      }
    }
  }
  #ossUpload {
    display: flex;
    align-items: flex-start;
    min-height: 70px;
  }
}
</style>
