
import { getBillNo } from "@/api/codeRule";
import { deepCopy,getFormattedDate } from '@/utils'

export const mixin = {
  data() {
    return {
      antiShake: false // 防抖
    }
  },
  methods: {
    //数据初始化
    async reset() {
      await this.$nextTick()
      console.log('进来吗', this.$refs.detailPage?.$refs)
      this.$refs.detailPage?.$refs?.myForm?.resetFields()
      this.options[this.options.rowKey] = ''
      this.options.fileItems = []
      this.options.billStatus = null
      this.options.list = [{}]
      this.changeStatus()
      console.log('什么玩意', this.options)
    },
    // 初始化
    async init() {
      this.reset()
      const { name, query } = this.$route;
      console.log('到这里了吗',query)
      if (query.id) {
        // 编辑
        switch (name) {
          case 'CostPriceAdjustDetail': // 仓库管理 - 成本调整
          case 'FillDetail': // 会员管理 - 会员充值
            try {
              const { detail } = this.requests
              let { data } = await detail(query.id)
              const index = this.options.columns.findIndex(item => item.prop === this.options.showProp && item.type === "remoteSelect")
              this.options.columns[index] = {
                ...this.options.columns[index],
                option: {
                  ...this.options.columns[index].option,
                  showItem: data[this.options.listName] || []
                }
              }
              this.options = {
                ...this.options,
                ...data,
                list: data[this.options.listName]
              }
              this.changeStatus()
            } catch (err) {
              this.$message.error('获取单据详情失败')
            } finally {
              this.options.loading = false
              //默认表格第一行高亮+可编辑
              await this.$nextTick()
              const $table = this.$refs?.detailPage?.$refs?.vxeTable?.$refs?.xTable
              $table.setCurrentRow($table.getData()[0]);
              $table.setEditRow($table.getData()[0]);
            }
            break;
          default:
            break;
        }
      } else {
        // 新增
        switch (name) {
          case 'CostPriceAdjustDetail': // 仓库管理 - 成本调整
          case 'FillDetail': // 会员管理 - 会员充值
            try {
              const { billType } = this.options || {};
              this.options.billNo = await getBillNo(billType)
              this.options.billDate = getFormattedDate(2, "-");
            } catch (err) {
              //
            } finally {
              this.options.loading = false
              //默认表格第一行高亮+可编辑
              await this.$nextTick()
              const $table = this.$refs?.detailPage?.$refs?.vxeTable?.$refs?.xTable
              $table.setCurrentRow($table.getData()[0]);
              $table.setEditRow($table.getData()[0]);
            }
            break;
          default:
            break;
        }
      }
    },
    // 表单初始化
    async formatFormStart() {
      let formData = deepCopy(this.options || {})
      let form = {}
      formData.formItemOpt.forEach(x => {
        form[x.model] = formData[x.model]
      })
      if (formData.fileItems) form.fileItems = formData.fileItems
      form[this.options.listName] = formData.list
      form[this.options.rowKey] = this.options[this.options.rowKey]
        ? this.options[this.options.rowKey]
        : ''

      return form
    },
    // 修改单据状态
    changeStatus() {
      let disabled = ['2', '3', '4', 2, 3, 4].includes(this.options.billStatus)
      this.options.formItemOpt = this.options.formItemOpt.map(item => ({
        ...item,
        disabled: typeof item.disabled === 'function' ? item.disabled : item.originalDisabled || disabled
      }))
      this.options.columns = this.options.columns.map(item => {
        item.disabled = item.originalDisabled || disabled
        if (item.children) {
          item.children = item.children.map(item_value => ({
            ...item_value,
            disabled: disabled
          }))
        }
        return { ...item }
      })
      this.options.buttons = this.options.buttons.map(item => {
        const disabled = ({
          'save': !this.options.billStatus || this.options.billStatus === '0',
          'save-add': !this.options.billStatus || this.options.billStatus === '0',
          'audit': this.options.billStatus === '0' || this.options.billStatus === 0,
          'termination': this.options.billStatus === '2' || this.options.billStatus === 2
        })[item.click] ? false : true;
        return {
          ...item,
          disabled: typeof item.disabled == 'function' ? item.disabled : disabled
        };
      });
      this.options.disabled = disabled
    },
    //保存
    billSave(type, isAudit = false) {
      return new Promise(async (resolve, reject) => {
        try {
          this.options.loading = true
          const { add, update } = this.requests
          if (this.options[this.options.rowKey]) {
            //编辑
            const form = await this.formatFormStart()
            let res = await update(form)

            this.options = {
              ...this.options,
              ...res.data,
            }
            res = undefined
            this.options.loading = false;
            this.$message.success('编辑成功')
          } else {
            //新增
            const form = await this.formatFormStart()
            let res = await add(form)
            this.$message.success('新增成功')
            if (this.$route.name == 'CheckGuide') {
              this.$store.commit('parameter/SET_BUSINESSKEEPALIVE', 'CheckDetail')
              this.$router.push({
                name: 'CheckDetail',
                query: {
                  type: 'update',
                  title: `编辑门店盘点`,
                  id: res.data.billId,
                  oldName: 'CheckGuide'
                }
              })
            } else {
              this.options = {
                ...this.options,
                ...res.data
              }
            }
            res = undefined
            this.options.loading = false;
          }
          if (isAudit) {
            this.$confirm('是否直接审核?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              closeOnClickModal: false
            }).then(async () => {
              try {
                await this.billChangeStatus('audit')
                if (type == 'save-add') {
                  this.reset()
                  this.$route.query.id = null
                  this.init()
                  this.options.loading = true
                }
              } catch (err) {
                this.changeStatus()
              }

            }).catch(async () => {
              if (type == 'save-add') {
                this.options.loading = true
                this.reset()
                this.$route.query.id = null
                this.init()
                this.options.loading = false
              } else {
                this.changeStatus()
              }
            });
          } else {
            if (type == 'save-add') {
              this.options.loading = true
              this.reset()
              this.$route.query.id = null
              this.init()
              this.options.loading = false;
            } else {
              this.changeStatus()
            }
          }
          resolve()
        } catch (err) {
          this.options.loading = false
          reject(err)
        }
      })
    },
    //更改单据状态
    billChangeStatus(type) {
      return new Promise(async (resolve, reject) => {
        console.log('进来审核了两次吗')
        //审核前先保存
        if (!['2', '3', '4',2,3,4].includes(this.options.billStatus)) {
          try {
            this.antiShake = false;
            await this.handleForm('save', { isAudit: 0 })
            // await this.billSave(type, false)
          } catch (err) {
            this.$message.error(err?.message || err)
            this.antiShake = false
            return reject(err)
          }
        }
        const configKeys = {
          audit: {
            executeFront: '0',
            executeBack: '2',
            errorMsg: '请操作已保存未审核的单据',
            successMsg: '审核成功'
          },
          reverseAudit: {
            executeFront: '2',
            executeBack: '0',
            errorMsg: '请操作已保存已审核的单据',
            successMsg: '反审核成功'
          },
          termination: {
            executeFront: '2',
            executeBack: '4',
            errorMsg: '请操作已保存已审核的单据',
            successMsg: '终止成功'
          }
        }
        console.log('什么鬼',configKeys[type])
        if (configKeys[type]?.executeFront != this.options.billStatus) {
          this.$message.error(configKeys[type].errorMsg)
          return reject()
        }
        const { updateStatus } = this.requests
        try {
          this.options.loading = true;
          const res = await updateStatus({
            billIds: [this.options[this.options.rowKey]],
            billStatus: configKeys[type].executeBack,
            billType: this.options.billType || ''
          })
          this.options.billStatus = res.data.billStatus
          this.options.billStatusName = res.data?.billStatusName || ''
          this.changeStatus()
          this.options.loading = false;
          this.$message.success(configKeys[type].successMsg)
        } catch (err) {
          this.options.loading = false;
          this.$message.error(err.message)
          reject(err)
        }
        resolve()
      })
    },
    async handleForm(type, row) {
      return new Promise(async (resolve, reject) => {
        if (['2'].includes(this.options.billStatus) && ['save', 'save-add', 'audit'].includes(type)) {
          this.$message.error('已审核的单据不支持此操作')
          return reject('已审核的单据不支持此操作')
        }
        if (['3', '4'].includes(this.options.billStatus) && ['save', 'save-add', 'audit', 'reverseAudit'].includes(type)) {
          this.$message.error(`${this.options.billStatus == '3' ? '已删除' : '已终止'}的单据不支持此操作`)
          return reject(`${this.options.billStatus == '3' ? '已删除' : '已终止'}的单据不支持此操作`)
        }
        //表单校验
        try {
          await this.$refs.detailPage.$refs.myForm.validate()
        } catch (err) {
          this.$message.error('请把带有红星的必填项输入完整!')
          return reject('请把带有红星的必填项输入完整!')
        }
        try {
          let tableValidate = await this.$refs.detailPage.$refs.vxeTable.$refs.xTable.validate(true)
          if (tableValidate) {
            const [[{ row = 0 } = {}] = []] = Object.values(tableValidate) || [];
            // this.$refs.detailPage.$refs.vxeTable.$refs.xTable.setCurrentRow(row);
            this.$message.error('请把带有红星的必填项输入完整!')
            return reject('请把带有红星的必填项输入完整!')
          }
        } catch (err) {
          this.$message.error('请把带有红星的必填项输入完整!')
          return reject('请把带有红星的必填项输入完整!')
        }
        this.options.list = this.options.list.filter(item => item[this.options.tableKey])
        if (!this.options.list || this.options.list.length <= 0 && this.options.checkMode != 4) {
          if (this.$route.name != 'CheckGuide') this.options.list = [{}]
          this.$message.error('请选择列表数据')
          return reject('请选择列表数据')
        }
        this.antiShake = true
        switch (type) {
          case 'save':
          case 'save-add':
            try {
              await this.billSave(type, row.isAudit === 0 ? false : true)

              this.antiShake = false
            } catch (err) {
              // this.$message.error(err?.message || err)
              this.antiShake = false
            }
            break
          case 'audit':
          case 'reverseAudit':
          case 'termination':

            try {
              await this.billChangeStatus(type)
              this.antiShake = false
            } catch (err) {
              this.antiShake = false
            }
            break
          default:
            break
        }
        resolve()
      })
    },
    //退出
    quit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({
        name: this.options.quitPageName,
      });
    },
  }
}
